<template>
  <div class="divider" style="background-color: black; height:2px;"/>
  <div class="div_title no-result" style="font-weight: bold;" id="forward-id">주문정보</div>
  <div>
    <div v-if="authType !== '3'" id="cart-wrapper" class="mypage-wrapper card">
      <div style="width: 150px;"> 
        <img id='cart-img' class="margin10 link" :src="(order.IMG_URL === '' || undefined) ? './assets/geek9_logo.jpg' : order.IMG_URL"/>
      </div>
      <div id="cart-container" >
        <div class="mypage-cart-wrapper"> 
          <p><label>대행유형</label>{{ order.ORDER_TYPE }} </p>
          <p><label>상품이름</label>{{ order.NAME }} </p>
          <p>
            <label>진행상태</label>{{ this.api.getOrderStatus(order.STATUS) }}
          </p>
          <p><label>정산여부</label>{{ (order.NOTICE.indexOf('입금완료') !== -1 || order.NOTICE.indexOf('결제완료') !== -1 || order.NOTICE.indexOf('정산완료') !== -1) ? '정산완료(' + this.api.getDateByFormat(order.NOTICE.substring(order.NOTICE.indexOf('(') + 1, order.NOTICE.indexOf(')')), 'YYYY/MM/DD') + ')' : '정산전'  }}</p>
          <p v-if="!(order.DELIVERY_FOREIGN_COMPANY_ORDER_ID === null || order.DELIVERY_FOREIGN_COMPANY_ORDER_ID === 'null')"><label>배송번호</label>{{ order.DELIVERY_FOREIGN_COMPANY_ORDER_ID }} </p>
        </div>
        <div class="mypage-cart-wrapper"> 
          <p><label>주문날짜</label>{{ this.api.getDateByFormat(order.REG_DATE.substring(0,8), 'YYYY.MM.DD') }}</p>
          <p><label>상품링크</label><a :href="order.URL" target="_blank">클릭</a></p>
          <p>
            <label>해외가격</label>{{ order.ORIGIN_PRICE }} {{this.api.getCurrency(order.COUNTRY)}}
          </p>
          <p class="tracking-label">
            <label>주문번호</label>
            <input class="tracking-input" type="text" ref="orderNumber" :value="order.ORDER_NUMBER" placeholder="주문번호" :disabled ="order.ORDER_TYPE === '배송대행'" />
            <Button size="small" label="저장" @click="click({type:'UPDATE_ORDER_BY_FORWARDER', val:this.$refs.orderNumber.value, condition:'orderNumber'})" :disabled ="order.ORDER_TYPE === '배송대행'"  /> 
          </p>
          <p class="tracking-label">
            <label>추적번호</label>
            <input class="tracking-input" type="text" ref="trackingNumber" :value="order.TRACKING_NUMBER" placeholder="해외추적번호" />
            <Button size="small" label="저장" @click="click({type:'UPDATE_ORDER_BY_FORWARDER', val:this.$refs.trackingNumber.value, condition:'trackingNumber'})" /> 
          </p>
        </div>
      </div>
    </div>
    <div v-else >
      <div id="cart-wrapper" class="mypage-wrapper card">
        <div id="cart-container" >
          <div class="mypage-cart-wrapper"> 
            <p class="div_title2">
              <label>주문날짜</label>
              <span style="text-align: center; width:100px;">{{ this.api.getDateByFormat(order.REG_DATE.substring(0,8), 'YYYY.MM.DD') }}</span></p>
            <p class="div_title2">
              <label>진행상태</label>
              <span style="text-align: center; width:100px;">{{ this.api.getOrderStatus(order.STATUS) }}</span>
            </p>
            <p class="div_title2">
              <label>해외가격</label>
              <span style="text-align: center; width:100px;">{{ Number(order.ORIGIN_PRICE).toLocaleString() }} {{ this.api.getCurrency(order.COUNTRY) }}</span>
            </p>
            <p class="div_title2">
              <label>대행유형</label>
              <span style="text-align: center; width:100px;">{{ order.ORDER_TYPE }}</span>
            </p>
            <p class="div_title2">
              <label>상품링크</label>
              <span style="text-align: center; width:100px;"><a :href="order.URL" target="_blank">클릭</a></span>
            </p>
            <p class="div_title2">
              <label>구매수량</label>
              <span style="text-align: center; width:100px;">{{ order.AMOUNT }} EA</span>
            </p> 
          </div>
          <div class="mypage-cart-wrapper"> 
            <p class="div_title2">
              <label>구매금액</label>
              <input class="tracking-input" type="text" ref="payPrice" style="font-size: 14px;" :value="order.PAY_PRICE" placeholder="대행비용" :disabled="order.PAY_PRICE !== 0"/>
              <Button size="small" label="저장" @click="click({type:'UPDATE_ORDER_BY_FORWARDER', val:this.$refs.payPrice.value, condition:'payPrice'})" :disabled="order.PAY_PRICE !== 0" /> 
            </p>
            <p class="div_title2">
              <label>주문번호</label>
              <input class="tracking-input" type="text" ref="orderNumber" style="font-size: 14px;" :value="order.ORDER_NUMBER" placeholder="Order Number" />
              <Button size="small" label="저장" @click="click({type:'UPDATE_ORDER_BY_FORWARDER', val:this.$refs.orderNumber.value, condition:'orderNumber'})"  /> 
            </p>
            <p class="div_title2">
              <label>추적번호</label>
              <input class="tracking-input" type="text" ref="trackingNumber" style="font-size: 14px;" :value="order.TRACKING_NUMBER" placeholder="해외추적번호" />
              <Button size="small" label="저장" @click="click({type:'UPDATE_ORDER_BY_FORWARDER', val:this.$refs.trackingNumber.value, condition:'trackingNumber'})" /> 
            </p>
            <p class="div_title2">
              <label>배송비용</label>
              <input class="tracking-input" type="text" ref="deliveryPrice" :value="order.DELIVERY_PRICE1" style="font-size: 14px;" placeholder="배송비" :disabled="order.DELIVERY_PRICE1 !== 0"/>
              <Button size="small" label="저장" @click="click({type:'UPDATE_ORDER_BY_FORWARDER', val:this.$refs.deliveryPrice.value, condition:'deliveryPrice1'})" :disabled="order.DELIVERY_PRICE1 !== 0"/> 
            </p>
            <p class="div_title2">
              <select ref="deliveryKrCompany" class="tracking-input">  
                <option value = "">택배사선택</option>
                <option value = "Qxpress">Qxpress</option>
                <option value = "DHL">DHL</option>
                <option value = "FEDEX">FEDEX</option>
                <option value = "UPS">UPS</option>
                <option value = "우체국">우체국</option>
                <option value = "우체국EMS">우체국EMS</option>
                <option value = "CJ대한통운">CJ대한통운</option>
                <option value = "SF Express">SF Express</option>
                <option value = "롯데택배">롯데택배</option>
                <option value = "HK POST">HK POST</option>
                <option value = "한진택배">한진택배</option>
                <option value = "로젠택배">로젠택배</option>
                <option value = "KG옐로우캡택배">KG옐로우캡택배</option>
                <option value = "현대택배">현대택배</option>
                <option value = "동부익스프레스">동부익스프레스</option>
                <option value = "KGB택배">KGB택배</option>
                <option value = "대신택배">대신택배</option>
                <option value = "경동택배">경동택배</option>
                <option value = "천일택배">천일택배</option>
                <option value = "이노지스택배">이노지스택배</option>
                <option value = "일양로지스택배">일양로지스택배</option>
                <option value = "CVSnet편의점택배">CVSnet편의점택배</option>
                <option value = "한덱스">한덱스</option>
                <option value = "하나로택배">하나로택배</option>
                <option value = "TNT">TNT</option>
                <option value = "GTX">GTX</option>
                <option value = "건영택배">건영택배</option>
                <option value = "HB한방택배">HB한방택배</option>
                <option value = "Singpost">Singpost</option>
              </select>
              <input class="tracking-input" type="text" ref="invoiceNumber" :value="order.INVOICE_NUMBER" style="font-size: 14px;" placeholder="한국송장번호" />
              <Button size="small" label="저장" @click="click({type:'UPDATE_ORDER_BY_FORWARDER', val:this.$refs.invoiceNumber.value, deliveryKrCompany:this.$refs.deliveryKrCompany.value, condition:'invoiceNumber'})" /> 
            </p>
          </div>
        </div>
      </div>
      <div class="div_title no-result" style="font-weight: bold;">
        <label>수취인정보</label>
        <Button ref="orderInfoBtn" size="small" label="조회" @click="click({type:'SELECT_ORDER_INFO_BY_UID'})" /> 
      </div>
      <div class="card" >
        <div v-if="orderName === ''" class="no-result">조회 버튼을 눌러주세요!</div>
        <table v-else class="dynamic-table" style="margin-bottom:0px;">
          <tr style="height:100px;">
            <td style="width:100%;">
              <table class="order_sub_table" style="table-layout:fixed;">
                <tbody style="text-align: left;">
                  <tr style="height:30px;">
                    <td class="container_sub_style">
                      <div class="div_center order-div">
                        <div>이름</div>
                        <div>
                          {{orderName}}
                        </div>
                      </div>
                    </td>
                    <td class="container_sub_style">
                      <div class="div_center order-div">
                        <div>폰번호</div>
                        <div>
                          {{phone}}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr style="height:30px;">
                    <td class="container_sub_style" colspan="2">
                      <div class="div_center order-div">
                        <div>통관부호</div>
                        <div>
                          {{ clearance }}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr style="height:30px;">
                    <td class="container_sub_style" colspan="2">
                      <div class="div_center order-div">
                        <div>주소</div>
                        <div>
                          ({{ addrZip }}) &nbsp; {{ addrRoad }} &nbsp; {{ addrDetail }}
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="div_title no-result" style="font-weight: bold;">
      <label>[IDX {{order.IDX}}] 문의/메시지</label>
    </div>
    <div class="card" >
      <Tabs :value="mIdx">
        <TabList>
          <Tab value="0">문의</Tab>
          <Tab value="1" @click="procGetQna({page:this.page, orderList:order.IDX})">내역</Tab>
        </TabList>
        <TabPanels>
          <TabPanel value="0">
            <Textarea v-model="msg" autofocus fluid style="width:100%;min-height:100px;" autoResize placeholder="해당 주문에 대하여 전달할 내용이나 문의사항을 알려주시면 곧 답변드리겠습니다." />
            <div class="div_title2 margin10">
              <label type="button" id="pic" />
              <span>
                <label class="attach link" type="button" for="inputFile">파일첨부</label>
                <label ref="picName" class="attach_label" for="inputFile"></label>
                <input type="file" id="inputFile" @change="readFile($event)" style="display:none"> &nbsp;
                <Button size="small" label="등록" @click="click({type:'INSERT_QNA', country:order.COUNTRY, id:this.api.getCookies('id'), orderList:order.IDX, content:this.msg, phone:'', mail:this.api.getCookies('mail'), orderName: this.api.getCookies('nickname'), fileName:this.fileName})" />
              </span>
            </div>
          </TabPanel>
          <TabPanel value="1">
            <div id="sub_menu" style="margin-bottom:20px; text-align:center" >
              <div v-if="qnaList.length === 0" class="no-result"> 
                문의내역이 없습니다.
              </div>
              <div v-else>
                <DataTable v-model:expandedRows="expandedRows" :value="qnaList" dataKey="IDX" @rowExpand="onRowExpand" stripedRows>
                  <Column expander />
                  <Column field="QNA_DATE" header="문의일" />
                  <Column field="TITLE" header="문의내용" >
                    <template #body="slotProps">
                      <span>{{ slotProps.data.TITLE }}</span>
                      &nbsp;<i v-if="!(slotProps.data.FILE_NAME === undefined || slotProps.data.FILE_NAME === '') || !(slotProps.data.REPLY_FILE_NAME === null || slotProps.data.REPLY_FILE_NAME === undefined || slotProps.data.REPLY_FILE_NAME === '')" class="pi pi-spin pi-inbox" style="font-size: 0.8rem"></i>
                    </template>
                  </Column>
                  <Column field="REPLY_YN" header="답변" />
                  <template #expansion="slotProps" >
                    <qnac @remove="remove" class="content" :contents="contents" :idx="slotProps.data.IDX" :content="slotProps.data.CONTENT" :country="slotProps.data.COUNTRY" :mail="slotProps.data.MAIL" :orderList="slotProps.data.ORDER_LIST" :orderName="slotProps.data.ORDER_NAME" :phone="slotProps.data.PHONE" :qnaDate="slotProps.data.QNA_DATE" :reply="slotProps.data.REPLY" :fileName="slotProps.data.FILE_NAME" :replyFileName="slotProps.data.REPLY_FILE_NAME" :qa="slotProps.data.QA_YN" :key="qnaForwarderKey"/>
                  </template>
                </DataTable>
                <br>
                <label @click="paging('prev')" class="link">
                  이전
                </label>
                <span style="margin:20px">{{page}}</span>
                <label @click="paging('next')" class="link">
                  다음
                </label>
              </div>
            </div>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  </div>
  <div class="divider" />
</template>
<script>
import { ref } from 'vue'
import qnac from '@/components/content/qna_content.vue'
export default {
  setup() {
    function onRowExpand (event) {
      expandedRows.value = {[event.data.IDX]:true}
    }
    const expandedRows = ref({})
    return {
      onRowExpand, expandedRows
    }
  },
  components: {
    qnac
  },
  mounted() {
    if(this.$refs.invoiceNumber !== undefined) this.$refs.invoiceNumber.value = this.order.INVOICE_NUMBER
    if(this.$refs.deliveryPrice !== undefined) this.$refs.deliveryPrice.value = this.order.DELIVERY_PRICE1
    if(this.$refs.deliveryKrCompany !== undefined) this.$refs.deliveryKrCompany.value = this.order.DELIVERY_KR_COMPANY === '없음' || this.order.DELIVERY_KR_COMPANY === undefined || this.order.DELIVERY_KR_COMPANY === '선택' ? '' : this.order.DELIVERY_KR_COMPANY
    this.uid = this.order.UID
  },  
  data() { 
    return { 
      contents:[],
      qnaList:[],
      page:1,
      mIdx:'0',
      msg:'',
      fileName: '',
      notice:'',
      uid:'',
      addrDetail:'',
      addrRoad:'',
      addrZip:'',
      clearance:'',
      mail:'',
      orderName:'',
      phone:'',
      qnaForwarderKey:-1
    }
  },
  props: {
    forwarderId:String,
    authType:String,
    order:{}
  },
  methods: {
    async remove(json) {
      if(confirm("삭제할까요?")) {
        await this.api.axiosGql('DELETE_QNA', {type:'DELETE_QNA', idx:json.idx, id:this.api.getCookies('id'), fileName:json.fileName, replyFileName:json.replyFileName})
        .then( async () => {
          this.procGetQna(this.page)
          alert('삭제되었습니다.')
          this.expandedRows = {}
          this.mIdx = '1'
          this.procGetQna({page:this.page, orderList:this.order.ORDER_LIST})
        })
        .catch( (err) => {
          console.log(err)
          alert('다시 시도해주세요.')
          this.$notify('다시 시도해주세요.')
        })      
      }
    },
    async procGetQna(param) {
      this.getQna(param)
      .then( async (res) => {
        this.mIdx = '1'
        this.qnaList = []
        this.qnaList = res.data
        for(var i = 0; i < this.qnaList.length; i++) {
          const json = this.qnaList[i]
          this.qnaList[i].TITLE = String(json.CONTENT).substring(0, 40) + '...'
          this.qnaList[i].QNA_DATE = this.api.getDateByFormat(this.qnaList[i].QNA_DATE, 'YYYY.MM.DD')
          if(json.REPLY === null || json.REPLY === undefined || json.REPLY === '') this.qnaList[i].REPLY_YN = "N"
          else this.qnaList[i].REPLY_YN = "Y"
        }
        this.qnaForwarderKey++
      })
    },
    paging(type) {
      if(type === 'prev') {
        if(this.page === 1) {
          alert('첫 페이지입니다.')
          return
        } else this.page -= 1
      } else this.page += 1
      this.getQna({page:this.page})
      .then( async (res) => {
        if(res.data.length === 0) {
          this.page -= 1
          alert('마지막 페이지입니다.')
        } else {
          this.qnaList = res.data
          for(var i = 0; i < this.qnaList.length; i++) {
            const json = this.qnaList[i]
            this.qnaList[i].TITLE = String(json.CONTENT).substring(0, 40) + '...'
            this.qnaList[i].QNA_DATE = this.api.getDateByFormat(this.qnaList[i].QNA_DATE, 'YYYY.MM.DD')
            this.qnaList[i].REPLY_YN = !json.REPLY ? "답변전" : "답변완료"
            // if(json.REPLY === null || json.REPLY === undefined || json.REPLY === '') this.qnaList[i].REPLY_YN = "답변전"
            // else this.qnaList[i].REPLY_YN = "답변완료"
          }
          this.qnaForwarderKey++
        }
      }).catch( (err) => {
        console.log(err)
        if(type === 'prev') this.page += 1
        else this.page -= 1
      })
    },
    async getQna(param) {
      const page = param.page
      const orderList = param.orderList
      return this.api.axiosGql('SELECT_QNA_LIST', {type:'SELECT_QNA_LIST', orderList:orderList, id: ""+this.api.getCookies('id'), page: page})
    },
    async readFile(event) {
      this.file = event.target.files[0]
      if(this.file.size >= 3072000) this.emitter.emit('confirm', {message: '3M 이하 파일만 첨부가 가능합니다.', header:'파일첨부', icon:'pi pi-info-circle'}) 
      else {
        const type = 'UPLOAD_IMG'
        const formData = new FormData()
        formData.append("file", this.file)
        formData.append('service', 'geek9')
        formData.append('pbType', 'qna')
        formData.append('type', type)
        await this.api.axiosGql(type, formData)
        .then( async (res) => {
          this.fileName = res.data
          const preview = document.querySelector('#pic')
          preview.innerHTML = '첨부완료'
        })
      }  
    },
    async click(param) {
      const type = param.type
      if(type === 'UPDATE_ORDER_BY_FORWARDER') {
        const val = param.val
        const condition = param.condition
        param.idx = this.order.IDX
        if(val === undefined || val === '' || val === 0 || val === '0') this.emitter.emit('confirm', {message: '값을 입력해주세요.', header:this.api.getCookies('page'), icon:'pi pi-exclamation-triangle'})
        else {
          if(condition === 'invoiceNumber' && (param.deliveryKrCompany === '' || param.deliveryKrCompany === undefined)) this.emitter.emit('confirm', {message: '택배사를 선택해주세요..', header:this.api.getCookies('page'), icon:'pi pi-exclamation-triangle'})
          else if(confirm("업데이트할까요?")) {
            await this.api.axiosGql(type, param)
            .then( async (res) => { 
              if(res.status === 200) {
                this.$emit("search", {type:'SELECT_ORDER_BY_FORWARDER'})
                this.emitter.emit('confirm', {message: '변경되었습니다.', header:this.api.getCookies('page'), icon:'pi pi-info-circle'})

                if (condition === 'trackingNumber') {
                  const orderId = this.order.DELIVERY_FOREIGN_COMPANY_ORDER_ID;
                  
                  // 유효한 값이 있는지 확인
                  if (val && orderId) {
                    try {
                      // 주문 상세 정보 조회
                      const { data } = await this.api.axiosGql('SELECT_IPORTER_ORDER_DTL', {
                        type: 'SELECT_IPORTER_ORDER_DTL', 
                        orderId, 
                        trackingNumber: val
                      })
                      
                      // 성공적인 응답이고 주문 상태가 있는 경우 추적 URL 요청
                      if (data.status === 'success' && data.order_status) {
                        await this.api.axiosGql('IPORTER_API_TRACKING_URL', {
                          type: 'IPORTER_API_TRACKING_URL', 
                          order_id: orderId, 
                          tracking: val
                        })
                      }
                    } catch (error) {
                      console.error('주문 조회 중 오류 발생:', error);
                    }
                  }
                }
                
                // if(condition === 'trackingNumber') {
                //   const orderId = this.order.DELIVERY_FOREIGN_COMPANY_ORDER_ID
                //   if(!(val === undefined || orderId === undefined || orderId === '')) {
                //     const iporterResult = await this.api.axiosGql('SELECT_IPORTER_ORDER_DTL', {type:'SELECT_IPORTER_ORDER_DTL', orderId:orderId, trackingNumber:val})
                //     const json = iporterResult.data
                //     if(json.order_status !== '' && json.status === 'success') await this.api.axiosGql('IPORTER_API_TRACKING_URL', {type:'IPORTER_API_TRACKING_URL', order_id:orderId, tracking: val})
                //   }
                // }
              } else alert('다시 시도해주세요')
            })
          }
        }
      } else if(type === 'SELECT_ORDER_INFO_BY_UID') {
        await this.api.axiosGql(type, {type:type, ID: "", UID:this.uid})
        .then( async (res) => {
          const json = res.data
          this.addrDetail = json.ADDR_DETAIL
          this.addrRoad = json.ADDR_ROAD
          this.addrZip = json.ADDR_ZIP
          this.clearance = json.CLEARANCE
          this.mail = json.MAIL
          this.orderName = json.ORDER_NAME
          this.phone = json.PHONE
        })
      } else if(type === 'INSERT_QNA') {
        if(this.msg === '') this.emitter.emit('confirm', {message: '내용을 작성해주세요', header:this.api.getCookies('page'), icon:'pi pi-exclamation-triangle'})
        else {
          this.emitter.emit('dialog', {flag:false})
          await this.api.axiosGql('INSERT_QNA', param)
          .then( async (res) => {
            if(res.status === 200) {
              this.emitter.emit('confirm', {message: '등록되었습니다', header:this.api.getCookies('page'), icon:'pi pi-exclamation-triangle'})
              this.msg = ''
              const preview = document.querySelector('#pic')
              preview.innerHTML = ''
              this.procGetQna({page:this.page, orderList:param.orderList})
              this.mIdx = '1' 
            } else {
              this.emitter.emit('confirm', {message: '다시 시도해주세요', header:this.api.getCookies('page'), icon:'pi pi-exclamation-triangle'})
            }
          })
          .catch( (err) => {
            console.log(err)
            this.emitter.emit('confirm', {message: '다시 시도해주세요', header:this.api.getCookies('page'), icon:'pi pi-exclamation-triangle'})
          })
          .finally(()=> {
            this.emitter.emit('dialog', {flag:false})
          })
        }
      }
    }
  }
}
</script>

<style scoped>
.tracking-label {
  display: flex;
  align-items: center;
  width: 90%;
}
.label-text {
  white-space: nowrap;
  margin-right: 10px;
}
</style>