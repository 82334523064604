<template>
  <div class="div_title">
    <context :context=cate />
    <span>
      <InputText v-model="fowarderKeyword" v-on:keyup.enter="search({type:'SELECT_ORDER_BY_FORWARDER'})" placeholder="검색어" size="small" class="input-keyword"/>  
      <Button label="조회" v-on:click="search({type:'SELECT_ORDER_BY_FORWARDER', isSearchButton: true})" raised size="small" ></Button>
    </span>
  </div>
  <dashboard page="forwarder" class="card main" :key='dashboardKey' @login="this.$emit('login')" :forwarderOrders=forwarderOrders />
  <div key="forwarderKey">
    <div class="div_title">
      조회결과
    </div>
    <div class="card" >
      <div id="app-notice" v-if="forwarderOrders.length === 0 || filteredOrders.length === 0" class="no-result"> 
        조회 내역이 없습니다.
      </div>
      <div v-else>
        <DataTable stripedRows v-model:expandedRows="expandedRows" rowGroupMode="rowspan" groupRowsBy="UID" sortMode="single" sortField="IDX" :sortOrder="-1" v-model:selection="forwarderOrders" :value="filteredOrders" dataKey="IDX" @rowExpand="onRowExpand" style="text-align: center;" class="custom-datatable">
          <Column expander header="상세" style="width:20px;;background-color:white;color:black;" />
          <Column field="UID" header="합배송" style="background-color: white;color:black;width:80px;text-align: center;">
            <template #body="slotProps" >
              {{JSON.stringify(expandedRows).indexOf('"') === -1 ? getUidCnt(slotProps.data.UID) + '건' : ''}}
            </template>
          </Column>
          <Column field="IDX" header="IDX" style="background-color: white;color:black;width:35px;"/>
          <Column header="국가" field="COUNTRY" style="background-color: white;color:black;min-width:70px;"/>
          <Column header="상품명" style="background-color: white;color:black;" >
            <template #body="slotProps">
              <div class="div_title3">
                <span>
                  <label style="color:red;">({{slotProps.data.ORDER_TYPE}})</label>
                  {{ slotProps.data.NAME }}
                </span>
                <span>
                  <i style="color:red;" v-if="slotProps.data.STATUS === 2" >구매전&nbsp;</i>
                  <i style="color:red;" v-else-if="slotProps.data.NOTICE.indexOf('입금완료') !== -1 || slotProps.data.NOTICE.indexOf('결제완료') !== -1 || slotProps.data.NOTICE.indexOf('정산완료') !== -1" class="pi pi-dollar" >&nbsp;</i>
                  <i style="color:blue;" v-if="slotProps.data.STATUS === 6" class="pi pi-truck"></i>
                </span>
              </div>
            </template>
          </Column>
          <template #expansion="slotProps" >
            <forwarderc :forwarderId="this.api.FORWARDER.get(this.forwarderId).name" :order="slotProps.data" :authType="authType" @search="search" />
          </template>
        </DataTable>
        <br>
        <div class="div_title">
          <label v-if="isExpandAll === false" class="link" style="font-weight: normal;" @click="expandAll()">전체보기</label>
          <label v-else class="link" style="font-weight: normal;" @click="expandAll()">전체닫기</label>
          <span>
            <label @click="paging('prev')" class="link">
              이전
            </label>
            <span style="margin:20px">{{page}}</span>
            <label @click="paging('next')" class="link">
              다음
            </label>
          </span>
          <label @click="excel()" class="link" style="font-weight: normal;">Excel</label>
        </div>
      </div>
    </div>
      <Dialog v-model:visible="visible" modal header="Edit Profile" :style="{ width: '25rem' }">
        <template #header>
          <span style="font-weight: bold;">FORWARDER</span>
        </template>
        <div style="font-size: 12px;margin-bottom:5px;margin-left:5px;" >업체명을 입력해주세요</div>
        <InputText v-model="forwarderId" v-on:keyup.enter="click({type:'forwarderLogin'})" class="normalInput" placeholder="업체명" />
        <template #footer>
          <div class="div_title2" style="width:100%;">
            <div style="font-size: 12px;color:red;">{{ notice }}</div>
            <span>
              <Button label="취소" text severity="secondary" @click="click({type:'cancel'})" autofocus />
              <Button label="확인" @click="click({type:'forwarderLogin'})" autofocus />
            </span>
          </div>
        </template>
      </Dialog>
  </div>
  <div class="ad-container" style="width:100%;text-align: center;">
    <Adsense
      data-ad-client="ca-pub-6699354447815429" 
      data-ad-slot="8230887469"
      data-ad-format="horizontal"
      data-full-width-responsive="true">
    </Adsense>
  </div>
</template>

<script>
import { ref, inject } from 'vue'
import ssr from '@/components/common/ssr'
import context from '@/components/common/Context.vue'
import router from '@/router'
import forwarderc from '@/components/content/forwarder_content.vue'
import dashboard from '@/components/home/dashboard.vue'
const xlsx = require('xlsx')

export default {
  setup() {
    const forwarderOrders = ref([])
    const notice = ref("")
    const visible = ref(false);
    const expandedRows = ref([])
    let isExpandAll = ref(false)
    function onRowExpand(event) {
      // 객체에 속성 추가
      expandedRows.value = {
        ...expandedRows.value,
        [event.data.IDX]: true
      }
    }
    
    const expandAll = () => {
      if (isExpandAll.value) {
        // 모든 행 접기
        expandedRows.value = {}
      } else {
        // 모든 행 펼치기
        const allExpanded = {}
        forwarderOrders.value.forEach(order => {
          allExpanded[order.IDX] = true
        })
        expandedRows.value = allExpanded
      }
      isExpandAll.value = !isExpandAll.value
    }
    const setMeta = inject('setMeta')
    const title = 'GEEK9 > 포워더'
    const keywords = '알바,포워더,forwarder,재테크,geek9,직구,해외직구,구매대행,배송대행,핸들러,handler,부업'
    const description = '해외 협력사/포워더 전용 페이지'
    const geek9Url = ssr.GEEK9_URL + 'forwarder'
    setMeta({
      title: title,
      keywords: keywords,
      description: description,
      'og:title': title,
      'og:description': description,
      'og:image': 'https://geek9.kr/img/logo_big.e0186dc8.jpg',
      'og:url': geek9Url,
      'canonical': geek9Url
    })
    const cate = ref([
      { label: '포워더' }
    ])
    const addJsonLd = () => {
      let flag = true
      document.querySelectorAll('script').forEach(el => {
        if (el.getAttribute('type') === 'application/ld+json' ) {
          flag = false
          return
        }
      })
      if(flag === true) {
        const script = document.createElement('script')
        script.setAttribute('type', 'application/ld+json')
        script.textContent = JSON.stringify(
          {
            "@context": "https://schema.org",
            "@graph": [
              ssr.getWebPage(geek9Url, title, ssr.GEEK9_URL, description),
            ]
          }
        )
        document.head.appendChild(script)
      }
    }
    return {
      cate, addJsonLd, onRowExpand, expandedRows, forwarderOrders, expandAll, isExpandAll, visible, notice
    }
  },
  mounted() {
    this.searchForwarder()
    this.addJsonLd()
    this.emitter.on('forwarderReload', this.reload)
    this.api.setPage('forwarder')
    console.log(this.api.getCookies('page'))
    this.search({type:'SELECT_USER_BY_TYPE'})
    
  },
  unmounted() {
    this.emitter.off('forwarderReload', this.reload)
    if (this.api.FORWARDER) {
      this.api.FORWARDER.clear()
    }
  },
  components: {
    context, forwarderc, dashboard
  },
  data() { 
    return { 
      forwarderKey:-1,
      forwarderId:'',
      currentStatus: null,
      page:1,
      fowarderKeyword:'',
      authType:'-1',
      date: 'r2',
      dashboardKey: 0,
      filteredOrders: []
    }
  },
  methods: {
    async refresh() {
      const forwarder = this.api.FORWARDER.get(this.forwarderId)
      this.emitter.emit('refresh' + this.api.getCookies('page'), {range: this.date, forwarder:forwarder})
    },
    click(param) {
      const type = param.type
      if(type === 'forwarderLogin') {
        if(this.forwarderId === '') {
          this.notice = '업체명 필수'
        } else {
          if(this.api.FORWARDER.has(this.forwarderId)) {
            this.api.setCookies('id', this.forwarderId)
            this.visible = false  
            this.emitter.emit('confirm', {message: '포워더로 접속되었습니다.', header:'가입', icon:'pi pi-info-circle'})
            this.search({type:'SELECT_ORDER_BY_FORWARDER'})
          } else {
            this.emitter.emit('confirm', {message: '등록된 포워더가 아닙니다', header:String(this.api.getCookies('page')).toUpperCase(), icon:'pi pi-exclamation-triangle'})
            router.push({name:"contract"})
          }
          this.notice = ''
          this.visible = false
        }
      } else if(type === 'cancel') {
        this.visible = false
        router.push({name:"contract"})
      }
    }, 
    async excel() {
      let execelList = Array()
      for(let i=0; i<this.filteredOrders.length; i++) {
        const item = this.filteredOrders[i]
        execelList.push({
          DATE:this.api.getDateByFormat((item.REG_DATE).substring(0,8), 'YYYY/MM/DD'),
          IDX:item.IDX, 
          COUNTRY:item.COUNTRY,
          ITEM:item.NAME,
          STATUS:this.api.getOrderStatus(Number(item.STATUS)),
          TRACKING_NUMBER:item.TRACKING_NUMBER,
          URL:item.URL,
          정산여부:(item.NOTICE.indexOf('입금완료') !== -1 || item.NOTICE.indexOf('결제완료') !== -1 || item.NOTICE.indexOf('정산완료') !== -1) ? '정산완료' : '정산전'
        })
      }
      const workBook = xlsx.utils.book_new()
      const workSheet = xlsx.utils.json_to_sheet(execelList)
      xlsx.utils.book_append_sheet(workBook, workSheet, 'geek9.kr')
      // 파일명에 현재 상태 정보 추가 (필터링된 경우)
      let fileName = 'GEEK9_' + this.api.getDateByFormat(new Date(), 'YYYYMMDD')
      if (this.currentStatus !== null) {
        fileName += '_' + this.api.getOrderStatus(this.currentStatus)
      }
      fileName += '.xlsx'
      xlsx.writeFile(workBook, fileName)
    },
    async paging(type) {
      const newPage = type === 'prev' ? this.page - 1 : this.page + 1
      
      if(newPage < 1) {
        this.emitter.emit('confirm', {
          message: '첫 페이지입니다', 
          header: String(this.api.getCookies('page')).toUpperCase(), 
          icon: 'pi pi-exclamation-triangle'
        })
        return
      }

      const prevPage = this.page
      this.page = newPage

      const params = {
        type: 'SELECT_ORDER_BY_FORWARDER',
        name: this.api.FORWARDER.get(this.api.getCookies('id')).name,
        page: this.page,
        keyword: this.fowarderKeyword
      }

      // 현재 적용된 status 필터가 있으면 추가
      if (this.currentStatus !== null) {
        params.status = this.currentStatus
      }

      try {
        const res = await this.api.axiosGql('SELECT_ORDER_BY_FORWARDER', params)
        
        if(res.data.length === 0) {
          this.page = prevPage // 이전 페이지로 복원
          this.emitter.emit('confirm', {
            message: '마지막 페이지입니다', 
            header: String(this.api.getCookies('page')).toUpperCase(), 
            icon: 'pi pi-exclamation-triangle'
          })
        } else {
          this.forwarderOrders = res.data
          // 필터링된 결과 업데이트
          this.updateFilteredOrders(res.data)
          this.dashboardKey++
        }
      } catch(err) {
        console.error(err)
        this.page = prevPage // 에러 시 이전 페이지로 복원
      }
    },
    async searchForwarder() {
      if(this.api.FORWARDER.size === 0) {
        await this.api.axiosGql('SELECT_CONSUMER', {type:'SELECT_CONSUMER', userType:2 })
        .then( (res) => {
          if(res.status === 200) {
            const forwarders = res.data
            for(let forwarder of forwarders) {
              const id = forwarder.ID 
              const name = forwarder.NAME 
              const type = forwarder.ADDR_ROAD
              const authType = forwarder.AUTH_TYPE
              this.api.FORWARDER.set(id, {name:name, type:type, authType:authType})
            }
          }
        })
      }
    },
    updateFilteredOrders(data) {
      if (this.currentStatus !== null) {
        this.filteredOrders = data.filter(order => 
          Number(order.STATUS) === this.currentStatus
        )
        
        // 필터링 결과가 없는 경우 처리
        if (this.filteredOrders.length === 0) {
          this.emitter.emit('confirm', {
            message: '해당 상태의 주문이 없습니다', 
            header: String(this.api.getCookies('page')).toUpperCase(), 
            icon: 'pi pi-exclamation-triangle'
          })
        }
      } else {
        this.filteredOrders = [...data]
      }
    },
    async search(param) {
      const type = param.type
      if(type === 'SELECT_USER_BY_TYPE') {
        this.fowarderKeyword
        const mail = this.api.getCookies('mail')
        
        await this.api.axiosGql(type, {type:type, id:this.api.getCookies('id'), mail: mail, userType:2})
        .then( async (res) => { 
          const json = res.data
          if(json === undefined || json.length === 0 || !this.api.FORWARDER.has(this.api.getCookies('id'))) this.visible = true
          else this.search({type:'SELECT_ORDER_BY_FORWARDER'})
        })
      } else if(type === 'SELECT_ORDER_BY_FORWARDER') {
        this.forwarderId = this.api.getCookies('id')
        const forwarder = this.api.FORWARDER.get(this.forwarderId)
        param.name = forwarder.name
        param.forwarderType = forwarder.type
        this.authType = forwarder.authType
        
        // 검색 버튼으로 호출된 경우
        if (param.isSearchButton) {
          this.page = 1 // 페이지 초기화
          this.currentStatus = null // status 필터 초기화
        }
        
        param.page = param.page || this.page
        param.keyword = this.fowarderKeyword

        // 현재 status 필터가 적용된 경우 파라미터 추가
        if (this.currentStatus !== null) {
          param.status = this.currentStatus
        }

        try {
          const res = await this.api.axiosGql(type, param)
          if(res.data.length === 0 && this.page > 1) {
            this.page--
            await this.search({type:'SELECT_ORDER_BY_FORWARDER'})
          } else {
            this.forwarderOrders = res.data
            this.updateFilteredOrders(res.data)
            this.dashboardKey++
          }
        } catch(err) {
          console.error(err)
          // 에러 처리
        }
      }
    },
    reload(flag) {
      if(flag === 'dashboard') {
        if(this.api.setCookies('id') === undefined || this.api.setCookies('id') === '') {
          router.push({name:"contract"})
        } else {
          this.forwarderKey++
          this.$emit("setMenu", {page:'forwarder'})
        }
      } else if(flag.indexOf('clickDashboard:') !== -1) {
        const status = Number(flag.split(':')[1])
        this.currentStatus = status // status 필터 업데이트

        // 현재 페이지의 데이터를 기준으로 필터링
        if (this.forwarderOrders.length > 0) {
          this.filteredOrders = this.forwarderOrders.filter(order => 
            Number(order.STATUS) === status
          )
        }

        // 필터링 결과가 없는 경우 처리
        if (this.filteredOrders.length === 0) {
          this.emitter.emit('confirm', {
            message: '해당 상태의 주문이 없습니다', 
            header: String(this.api.getCookies('page')).toUpperCase(), 
            icon: 'pi pi-exclamation-triangle'
          })
        }

        this.dashboardKey++ // 대시보드 업데이트
      }
    },
    getUidCnt(uid) {
      return this.forwarderOrders.filter(order => order.UID === uid).length
    }
  }
}
</script>
<style>
/* 기본 짝수 행 스타일 */
.custom-datatable .p-datatable-tbody > tr:nth-child(even):not(.p-highlight) > td {
  background-color: #f8f9fa !important;
}

/* 기본 홀수 행 스타일 */
.custom-datatable .p-datatable-tbody > tr:nth-child(odd):not(.p-highlight) > td {
  background-color: #ffffff !important;
}

/* 선택된 행 스타일 */
.custom-datatable .p-datatable-tbody > tr.p-highlight > td {
  background-color: #e3f2fd !important;
}

/* 선택된 행 호버 스타일 */
.custom-datatable .p-datatable-tbody > tr.p-highlight:hover > td {
  background-color: #bbdefb !important;
}

/* 일반 행 호버 스타일 */
.custom-datatable .p-datatable-tbody > tr:not(.p-highlight):hover > td {
  background-color: #f1f1f1 !important;
}

/* 확장된 행의 스타일 */
.custom-datatable .p-datatable-tbody > tr.p-row-expanded {
  background-color: #fff3e0 !important;
}

/* 확장된 콘텐츠 영역 스타일 */
.custom-datatable .p-datatable-tbody > tr.p-datatable-row-expansion {
  background-color: #fff8e1 !important;
}

/* 확장된 행이면서 선택된 행의 스타일 */
.custom-datatable .p-datatable-tbody > tr.p-row-expanded.p-highlight > td {
  background-color: #e3f2fd !important;
}

/* rowspan이 적용된 그룹화된 행들에 대한 스타일 */
.custom-datatable .p-datatable-tbody > tr.p-rowgroup-header:not(.p-highlight) td:first-child {
  background-color: inherit !important;
}

/* 체크박스 스타일 유지 */
.custom-datatable .p-checkbox .p-checkbox-box.p-highlight {
  background-color: #2196F3 !important;
  border-color: #2196F3 !important;
}
</style>
